<template>
	<div class="p-grid">
		<div class="p-col-12 p-md-12">
            <div class="card">
				<h4 style="width: 800px;">{{$t('Marine Insurance')}}</h4>
                <Steps :model="items[i18n.locale()]" :readonly="true" />
            </div>
            <router-view v-slot="{Component}" :formData="formObject" @prev-page="prevPage($event)" @next-page="nextPage($event)" @complete="complete">
                <keep-alive>
                    <component :is="Component" />
                </keep-alive>
            </router-view>
		</div>
	</div>
</template>
<script>
	import { useI18nPlugin } from '@unify/vuex-i18n';

	export default {
		data() {
			return {
				items: {
					en:[
						{
							label: 'Basic Information',
							to: '/quotation6'
						},
						{
							label: 'Property Information',
							to: '/quotation6/steps2'
						},
						{
							label: 'Coverage',
							to: '/quotation6/steps3'
						},
						{
							label: 'Business Continuity',
							to: '/quotation6/steps4'
						},
						{
							label: 'Confirmation',
							to: '/quotation6/steps5'
						}
					],
					es:[
						{
							label: 'Información Básica',
							to: '/quotation6'
						},
						{
							label: 'Información de la Propiedad',
							to: '/quotation6/steps2'
						},
						{
							label: 'Cobertura',
							to: '/quotation6/steps3'
						},
						{
							label: 'Continuidad de Negocio',
							to: '/quotation6/steps4'
						},
						{
							label: 'Confirmación',
							to: '/quotation6/steps5'
						}
					],
				},
				formObject: {},
				size: '60vw',
				i18n: null,
			}
		},
		created() {
			this.i18n = useI18nPlugin();
			if (this.$store.state.mobile){this.size = '90vw';}
			this.$store.commit('Validar',{path: '/quotation'});
		},
		methods: {
			nextPage(event) {
				for (let field in event.formData) {
					this.formObject[field] = event.formData[field];
				}
				this.$router.push(this.items[this.i18n.locale()][event.pageIndex + 1].to);
			},
			prevPage(event) {
				this.$router.push(this.items[this.i18n.locale()][event.pageIndex - 1].to);
			},
			complete() {
				this.$toast.add({severity:'success', summary:'Order submitted', detail: 'Dear, ' + this.formObject.firstname + ' ' + this.formObject.lastname + ' your order completed.'});
			},
		}
	}
</script>

